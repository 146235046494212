import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  getLatestCreditReportForUser,
  
  /* User & Auth */
  getUserProfile,
  resetPassword,
  signInWithMagicLink,
  signInWithPassword,
  signInWithProvider,
  signUp,
  updatePassword,
  updateUserProfileNameAndAvatar,

  /* Admin */
  updateUserProfile,
  setupUserProfile,
  getLatestCreditScoreUpdates,
  updateGrowthSettings,
  fetchGrowthSettings,
} from './supabase-queries';
import supabaseClient from './supabase-browser';
import { AuthProvider, Table, UnwrapPromise, View } from '@/types';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { uploadPublicUserAvatar } from './supabase-storage-queries';
import { useRef } from 'react';
import { DEMO_USER_EMAIL } from '@/constants';

/* ==================== */
/* USER PROFILE */
/* ==================== */

export const useUserProfile = (initialData?: View<'masked_user_profiles'>) => {
  const user = useLoggedInUser();
  // console.log('🚀 ~ file: react-query-hooks.ts:101 ~ user', user)
  return useQuery<View<'masked_user_profiles'>, Error>(
    ['user-profile', user?.id],
    async () => {
      if (!user) {
        throw new Error('User not found');
      }
      const userProfile = await getUserProfile(supabaseClient, user.id) as View<'masked_user_profiles'>
      // Assert non-null values or provide defaults
      return {
        ...userProfile,
        account_type: userProfile.account_type ?? 'inactive',
        address: userProfile.address ?? '',
        avatar_url: userProfile.avatar_url ?? '',
        customer_id: userProfile.customer_id ?? '',
        subscription_id: userProfile.subscription_id ?? '',
        // ... handle other potentially null fields similarly
      } as const;
    },
    {
      initialData,
      enabled: !!user, // Only run the query if user is available
      staleTime: 30 * 1000, // Cache for 30 seconds,
      refetchOnWindowFocus: true, // Enable refetch on window focus
      // This will only refetch if the data is stale (older than 30 seconds)
    }
  );
};

export const useUpdateUserProfile = ({
  onSuccess,
  onError,
  onMutate,
}: {
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
  onMutate?: () => void;
}) => {
  const user = useLoggedInUser();
  const queryClient = useQueryClient();
  const { data: currentUserProfile } = useUserProfile();
  
  return useMutation(
    async (data: Partial<Table<'user_profiles'>>) => {
      if (user && user.email === DEMO_USER_EMAIL)
        return true // If the user is the demo user, reject the mutation

      // Only include the phone field if it doesn't contain '*'
      const updatedData = { ...data };
      if (updatedData.phone && (updatedData.phone === currentUserProfile?.phone || updatedData.phone.includes('*'))) {
        delete updatedData.phone;
      }

      return updateUserProfile(supabaseClient, user.id, updatedData);
    },
    {
      onMutate: () => {
        onMutate?.();
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['user-profile', user.id]);
        onSuccess?.();
      },
      onError: (error) => {
        onError?.(error);
      },
    }
  );
};


export const useUpdateUserFullnameAndAvatarMutation = ({
  onSuccess,
  onError,
  onMutate,
}: {
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
  onMutate?: () => void;
}) => {
  const user = useLoggedInUser();
  const queryClient = useQueryClient();
  const toastRef = useRef<string | null>(null);
  
  return useMutation(
    async (data: { avatarUrl?: string; fullName?: string }) => {
      if (user && user.email === DEMO_USER_EMAIL)
        return true // If the user is the demo user, reject the mutation
    
      return updateUserProfileNameAndAvatar(supabaseClient, user.id, data);
    },
    {
      onMutate: () => {
        toastRef.current = toast.loading('Updating profile...');
        onMutate?.();
      },
      onSuccess: () => {
        toast.success('Profile updated', {
          id: toastRef.current || '',
        });
        queryClient.invalidateQueries(['user-profile', user.id]);
        onSuccess?.();
      },
      onError: (error) => {
        toast.error('Failed to update profile', {
          id: toastRef.current || '',
        });
        onError?.(error);
      },
    }
  );
};

export const useUploadUserAvatarMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (avatarUrlPath: string) => void;
  onError?: (error: unknown) => void;
}) => {
  const user = useLoggedInUser();
  const toastRef = useRef<string | null>(null);
  return useMutation(
    async (file: File) =>
      await uploadPublicUserAvatar(supabaseClient, user.id, file, file.name, false, {
        upsert: true,
      }),
    {
      onMutate: () => {
        toastRef.current = toast.loading('Uploading avatar...');
      },
      onSuccess: (avatarUrl: string) => {
        onSuccess?.(avatarUrl);
        toast.success('Avatar uploaded', {
          id: toastRef.current || '',
        });
      },
      onError: (error) => {
        onError?.(error);
        toast.error('Failed to upload avatar', {
          id: toastRef.current || '',
        });
      },
    }
  );
};


/* ================= */
/* Update email */
/* ================= */

export function useUpdateUserEmailMutation() {
  const queryClient = useQueryClient();
  const toastRef = useRef<string | null>(null);
  return useMutation(
    async ({ oldEmail, newEmail }: { oldEmail: string; newEmail: string }) => {
      
      if (oldEmail === DEMO_USER_EMAIL)
        // If the user is the demo user, reject the mutation
        return true
    
      const { data } = await supabaseClient.auth.updateUser({
        email: newEmail,
      });
      return data;
    },
    {
      onMutate: async () => {
        toastRef.current = toast.loading('Updating email...');
      },
      onSuccess: () => {
        toast.success('Email updated!', {
          id: toastRef.current || '',
        });
        toastRef.current = null;
        queryClient.invalidateQueries(['user']);
        window.location.reload();
      },
      onError: (error) => {
        toast.error(String(error), {
          id: toastRef.current || '',
        });
        toastRef.current = null;
      },
    }
  );
}

/* ==================== */
/* AUTH */
/* ==================== */

export const useSignInWithMagicLink = ({
  onSuccess,
  onMutate,
  onError,
}: {
  onSuccess?: () => void;
  onMutate?: () => void;
  onError?: (error: unknown) => void;
}) => {
  const toastRef = useRef<string | null>(null);
  return useMutation(
    async ({ email }: { email: string }) => {
      return signInWithMagicLink(supabaseClient, email);
    },
    {
      onMutate: () => {
        toastRef.current = toast.loading('Sending magic link...');
        onMutate?.();
      },
      onSuccess: () => {
        toast.success('Check your email for the magic link!', {
          id: toastRef.current || '',
        });

        toastRef.current = null;
        onSuccess?.();
      },
      onError: (error) => {
        toast.error(String(error), {
          id: toastRef.current || '',
        });
        toastRef.current = null;
        onError?.(error);
      },
    }
  );
};

export const useSignInWithPassword = ({
  onSuccess,
  onMutate,
  onError,
}: {
  onSuccess?: () => void;
  onMutate?: () => void;
  onError?: (error: unknown) => void;
}) => {
  const toastRef = useRef<string | null>(null);
  return useMutation(
    async ({ email, password }: { email: string; password: string }) => {
      return signInWithPassword(supabaseClient, email, password);
    },
    {
      onMutate: () => {
        toastRef.current = toast.loading('Signing in...');
        onMutate?.();
      },
      onSuccess: () => {
        toast.success('Signed in!', {
          id: toastRef.current || '',
        });

        toastRef.current = null;
        onSuccess?.();
      },
      onError: (error) => {
        toast.error(String(error), {
          id: toastRef.current || '',
        });
        toastRef.current = null;
        onError?.(error);
      },
    }
  );
};

export const useResetPassword = ({
  onSuccess,
  onMutate,
  onError,
}: {
  onSuccess?: () => void;
  onMutate?: () => void;
  onError?: (error: unknown) => void;
}) => {
  const toastRef = useRef<string | null>(null);
  return useMutation(
    async ({ email }: { email: string }) => {
      return resetPassword(supabaseClient, email);
    },
    {
      onMutate: () => {
        toastRef.current = toast.loading('Sending password reset email...');
        onMutate?.();
      },
      onSuccess: () => {
        toast.success('Check your email for the password reset link!', {
          id: toastRef.current || '',
        });

        toastRef.current = null;
        onSuccess?.();
      },
      onError: (error) => {
        toast.error(String(error), {
          id: toastRef.current || '',
        });
        toastRef.current = null;
        onError?.(error);
      },
    }
  );
};

export const useUpdatePassword = ({
  onSuccess,
  onMutate,
  onError,
}: {
  onSuccess?: () => void;
  onMutate?: () => void;
  onError?: (error: unknown) => void;
}) => {
  const toastRef = useRef<string | null>(null);
  return useMutation(
    async ({ password }: { password: string }) => {
      return updatePassword(supabaseClient, password);
    },
    {
      onMutate: () => {
        toastRef.current = toast.loading('Updating password...');
        onMutate?.();
      },
      onSuccess: () => {
        toast.success('Password updated!', {
          id: toastRef.current || '',
        });

        toastRef.current = null;
        onSuccess?.();
      },
      onError: (error) => {
        toast.error(String(error), {
          id: toastRef.current || '',
        });
        toastRef.current = null;
        onError?.(error);
      },
    }
  );
};

export const useSignInWithProvider = () => {
  const toastRef = useRef<string | null>(null);
  return useMutation(
    async ({ provider }: { provider: AuthProvider }) => {
      return signInWithProvider(supabaseClient, provider);
    },
    {
      onMutate: ({ provider }) => {
        toastRef.current = toast.loading(`Signing in with ${provider}...`);
      },
    }
  );
};

export const useSignUp = ({
  onSuccess,
  onMutate,
  onError,
}: {
  onSuccess?: () => void;
  onMutate?: () => void;
  onError?: (error: unknown) => void;
}) => {
  const toastRef = useRef<string | null>(null);
  return useMutation(
    async ({ name, email, phone, password }: { name: string; email: string; phone: string; password: string }) => {
      return signUp(supabaseClient, email, password, name, phone);
    },
    {
      onMutate: () => {
        toastRef.current = toast.loading('Signing up...');
        onMutate?.();
      },
      onSuccess: () => {
        toast.success(`Welcome to CreditCaptain! Let's get you started.`, {
          id: toastRef.current || '',
        });

        toastRef.current = null;
        onSuccess?.();
      },
      onError: (error) => {
        toast.error(String(error), {
          id: toastRef.current || '',
        });
        toastRef.current = null;
        onError?.(error);
      },
    }
  );
};

export const useSetup = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: any) => void;
  onError?: (error: unknown) => void;
}) => {
  const user = useLoggedInUser();
  const queryClient = useQueryClient();
  const toastRef = useRef<string | null>(null);

  return useMutation<any, unknown, {
    firstName?: string;
    lastName?: string;
    address?: {
      formattedAddress?: string;
      address1?: string;
      address2?: string;
      city?: string;
      region?: string;
      postalCode?: string;
      country?: string;
      lat?: number;
      lng?: number;
    };
    phone?: string;
    ssn?: string;
    growthActive?: boolean;
    dob?: Date;
  }>(
    async (profileData) => {
      if (!user) throw new Error('User not logged in');
      const updatedProfileData = { ...profileData };
      if (updatedProfileData.phone && updatedProfileData.phone.includes('*')) {
        delete updatedProfileData.phone;
      }
      
      const profile = await setupUserProfile(supabaseClient, user.id, updatedProfileData);

      if (!profile.stitch_user_id) {
        // If there's no stitch_user_id, we need to register with Stitch
        return { profile, needsStitchRegistration: true };
      } else {
        if (profile.identity_confirmed) {
          return { profile, needsStitchRegistration: false };
        } else {
          return { profile, needsStitchRegistration: true };
        }
      }
    },
    {
      onMutate: () => {
        // toastRef.current = toast.loading('Setting up your profile...');
      },
      onSuccess: (data) => {
        if (data.needsStitchRegistration) {
          // toast.success('Profile created. Please complete identity verification.', {
          //   id: toastRef.current || '',
          // });
          // toast.dismiss(toastRef.current || '');
        } else {
          // toast.success('Profile setup complete!', {
          //   id: toastRef.current || '',
          // });
        }
        queryClient.invalidateQueries(['user-profile', user?.id]);
        onSuccess?.(data);
      },
      onError: (error) => {
        toast.error(String(error), {
          id: toastRef.current || '',
        });
        onError?.(error);
      },
      onSettled: () => {
        toastRef.current = null;
      },
    }
  );
};

/* ==================== */
/* Growth Settings       */
/* ==================== */

export const useGrowthSettings = (userId: string) => {
  return useQuery(
    ['growth-settings', userId],
    () => fetchGrowthSettings(supabaseClient, userId),
    {
      enabled: !!userId,
      staleTime: 60 * 60 * 1000 // 1 hour cache
    }
  );
};

export const useUpdateGrowthSettings = (userId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (settings: any) => updateGrowthSettings(supabaseClient, userId, settings),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['growth-settings', userId]);
      }
    }
  );
};

/* ==================== */
/* Credit Reports       */
/* ==================== */

export type CreditReportsType = UnwrapPromise<ReturnType<typeof getLatestCreditReportForUser>>;

export const useLatestCreditReport = (userId: string) => {
  return useQuery<CreditReportsType>(
    ['credit-reports', userId],
    async () => {
      return getLatestCreditReportForUser(supabaseClient, userId);
    },
    {
      enabled: !!userId, // Only run query if userId exists
      refetchOnWindowFocus: false,
      staleTime: 60 * 60 * 1000, // 1 hour cache
    }
  );
};

export const useLatestCreditScoreUpdates = (userId: string) => {
  return useQuery(['latest-credit-score-updates', userId], async () => {
    return getLatestCreditScoreUpdates(supabaseClient, userId);
  }, {
    enabled: !!userId,
    refetchOnWindowFocus: false,
    staleTime: 60 * 60 * 1000, // 1 hour cache
  });
};
