import { AppSupabaseClient, SupabaseFileUploadOptions } from '@/types';
import urlJoin from 'url-join';

export const uploadUserImage = async (
  supabaseClient: AppSupabaseClient,
  userId: string,
  file: File,
  fileName: string,
  fileOptions?: SupabaseFileUploadOptions | undefined
): Promise<{
  path: string;
}> => {
  const userImagesPath = `${userId}/images/${fileName}`;
  const { data, error } = await supabaseClient.storage
    .from('user-assets')
    .upload(userImagesPath, file, fileOptions);

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export const uploadPublicUserAvatar = async (
  supabaseClient: AppSupabaseClient,
  userId: string,
  file: File,
  fileName: string,
  uploadInBackground: boolean = false,
  fileOptions?: SupabaseFileUploadOptions | undefined,
): Promise<string> => {
  const userImagesPath = `${userId}/images/${fileName}`;
  const filePath = userImagesPath.split(',')[0];
  const supabaseFileUrl = urlJoin(
    process.env.NEXT_PUBLIC_SUPABASE_URL,
    '/storage/v1/object/public/avatars',
    filePath
  );

  if (!fileOptions) {
    fileOptions = {
      upsert: true
    };
  }

  if (uploadInBackground) {
    supabaseClient.storage.from('avatars').upload(userImagesPath, file, fileOptions)
      .then((fileUrl) => {
        console.log('File upload finished: ', fileUrl);
      })
      .catch((error) => {
        throw new Error(error.message);
      });
    return supabaseFileUrl;
  } else {
    const { data, error } = await supabaseClient.storage
      .from('avatars')
      .upload(userImagesPath, file, fileOptions);

    if (error) 
      throw new Error(error.message);
    
    return supabaseFileUrl;
  }
};

export const uploadOrganizationImage = async (
  supabaseClient: AppSupabaseClient,
  organizationId: string,
  file: File,
  fileName: string,
  fileOptions?: SupabaseFileUploadOptions | undefined
): Promise<{
  path: string;
}> => {
  const organizationImages = `${organizationId}/images/${fileName}`;
  const { data, error } = await supabaseClient.storage
    .from('organization-assets')
    .upload(organizationImages, file, fileOptions);

  if (error) {
    throw new Error(error.message);
  }

  return data;
};
