import {User, useUser} from "@supabase/auth-helpers-react"
import supabaseClient from "@/utils/supabase-browser"
import {Notifications} from "@/lib/logsnag"

/**
 * The original useUser hook from @supabase/auth-helpers-react
 * is optionally undefined. This hook throws an error if the user is not logged in.
 * This is useful for pages that require the user to be logged in.
 *
 * Also handles rate limit errors by clearing auth cookies and logging to LogSnag.
 *
 * @returns The logged in user or throws an error if the user is not logged in
 */
export const useLoggedInUser = (): User => {
    try {
        const user = useUser()
        if (!user) {
            // Clear auth cookies by signing out locally when no user is detected
            supabaseClient.auth.signOut({scope: "local"}).catch((signOutError) => {
                console.error("Error signing out after no user detected:", signOutError)
            })
            throw new Error("User is not logged in")
        }
        return user
    } catch (error) {
        // Check if it's a rate limit error
        if (error && typeof error === "object" && "status" in error && "code" in error && error.status === 429 && error.code === "over_request_rate_limit") {
            console.error("Supabase rate limit reached, clearing auth cookies", error)

            // Send a notification through LogSnag for debugging
            try {
                Notifications.sendNotification("errors", "Supabase Rate Limit Error", "🚨", true, undefined, {
                    error_message: "Request rate limit reached",
                    error_code: "over_request_rate_limit",
                    status: 429
                })
            } catch (logSnagError) {
                console.error("Failed to send LogSnag notification:", logSnagError)
            }

            // Clear auth cookies by signing out locally
            supabaseClient.auth.signOut({scope: "local"}).catch((signOutError) => {
                console.error("Error signing out after rate limit:", signOutError)
            })
        }

        // Re-throw the error to be handled by error boundaries
        throw error
    }
}
