import { AppSupabaseClient, AuthProvider, Table } from '@/types';
import { ROOT_DOMAIN } from '@/constants';
import { User } from '@supabase/supabase-js';
import { errors } from './errors';
import { Tables, Database } from 'src/lib/database.types';
import { createClient } from '@supabase/supabase-js';

export const getActiveProductsWithPrices = async (supabase: AppSupabaseClient) => {
  console.log('Warning: getActiveProductsWithPrices was called but products table has been removed');
  return [];
};

export const updateUserName = async (supabase: AppSupabaseClient, user: User, name: string) => {
  await supabase
    .from('user_profiles')
    .update({ full_name: name })
    .eq('id', user.id);
};

export const getUserProfile = async (supabase: AppSupabaseClient, userId: string, unmaskedProfile: boolean = false): Promise<Table<'user_profiles'>> => {

  if (unmaskedProfile) {
    const { data, error } = await supabase
      .from('user_profiles')
      .select('*')
      .eq('id', userId)
      .limit(1);

    if (error) {
      console.error(error);
      throw error;
    }
    return data ? data[0] as Table<'user_profiles'> : null;
  } else {
    const { data, error } = await supabase
      .from('masked_user_profiles')
      .select('*')
      .eq('id', userId)
      .limit(1);

    if (error) {
      console.error(error);
      throw error;
    }
    return data ? data[0] as Table<'user_profiles'> : null;
  }
};


export const getUserByCustomerId = async (supabase: AppSupabaseClient, customerId: string) => {
  const { data, error } = await supabase
    .from('user_profiles')
    .select('*')
    .eq('customer_id', customerId)
    .limit(1)
    .single();

  if (error) {
    errors.add(error.message);
    throw error;
  }

  return data;
};

export const updateUserProfileNameAndAvatar = async (
  supabase: AppSupabaseClient,
  userId: string,
  {
    fullName,
    avatarUrl,
    email,
  }: {
    fullName?: string;
    avatarUrl?: string;
    email?: string;
  }
) => {
  const { data, error } = await supabase
    .from('user_profiles')
    .update({
      full_name: fullName,
      avatar_url: avatarUrl,
      email: email,
    })
    .eq('id', userId)
    .single();

  if (error) {
    errors.add(error.message);
    throw error;
  }

  return data;
};

export const updateUserProfile = async (
  supabase: AppSupabaseClient,
  userId: string,
  data: Partial<Tables<'user_profiles'>>
) => {
  const { data: updatedData, error } = await supabase
    .from('user_profiles')
    .update(data)
    .eq('id', userId)
    .single();

  if (error) {
    errors.add(error.message);
    throw error;
  }

  return updatedData;
};


/* ==================== */
/* Accounts */
/* ==================== */

//dummy function
export const createAccount = async (supabase: AppSupabaseClient, user: User, fetched_profile_data: any, accountPlatform: 'instagram' | 'tiktok', themeColor?: string) => {
  return {
    id: Math.random().toString(36).substring(7),
    created_at: new Date().toISOString()
  };
}

// FIXME: IMPLEMENT THIS FOR CreditCaptain
// export const createAccount = async (supabase: AppSupabaseClient, user: User, fetched_profile_data: TiktokProfileProps | InstagramProfileProps, accountPlatform: 'instagram' | 'tiktok', themeColor?: string) => {
//   console.log('🚀 ~ file: supabase-queries.ts:93 ~ user', user);
//   console.log('🚀 ~ file: supabase-queries.ts:94 ~ fetched_profile_data', fetched_profile_data);

//   const isTiktok = (data: TiktokProfileProps | InstagramProfileProps): data is TiktokProfileProps => accountPlatform === 'tiktok';
//   // // Make a call to /download-avatar API endpoint to download Instagram profile picture and upload to Supabase Storage
//   // const response = await fetch(`/api/accounts/download-avatar?avatarUrl=${encodeURIComponent(Instagram.decodeInstagramMediaURL(instagram_profile_data.graphql.user.profile_pic_url))}&userId=${user.id}&username=${instagram_profile_data.graphql.user.username}`);
//   // const { fileUrl: instagramSupabaseAvatarUrl } = await response.json();
//   // console.log("Avatar URL: ", instagramSupabaseAvatarUrl)

//   if (!fetched_profile_data)
//     throw new Error('No profile data found');

//   const db_account: Partial<Tables<'accounts'>> = {
//     username: isTiktok(fetched_profile_data) ? fetched_profile_data.username : fetched_profile_data.graphql.user.username,
//     platform: accountPlatform,
//     account_type: 'trial',
//     name: isTiktok(fetched_profile_data) ? fetched_profile_data.full_name : fetched_profile_data.graphql.user.full_name,
//     platform_id: isTiktok(fetched_profile_data) ? fetched_profile_data.id : fetched_profile_data.graphql.user.id,
//     followers: isTiktok(fetched_profile_data) ? fetched_profile_data.follower_count : fetched_profile_data.graphql.user.edge_followed_by.count,
//     starting_followers: isTiktok(fetched_profile_data) ? fetched_profile_data.follower_count : fetched_profile_data.graphql.user.edge_followed_by.count,
//     followings: isTiktok(fetched_profile_data) ? fetched_profile_data.following_count : fetched_profile_data.graphql.user.edge_follow.count,
//     ...(isTiktok(fetched_profile_data) && {
//       likes: fetched_profile_data.likes_count,
//       starting_likes: fetched_profile_data.likes_count,
//       medias: fetched_profile_data.media_count,
//     }),
//     conversions: 0,
//     actions: 0,
//     user_id: user.id,
//     active: false,
//     connected: true,
//     avatar_url: `https://cnobpwedmocmudahumkq.supabase.co/storage/v1/object/public/avatars/${isTiktok(fetched_profile_data) ? fetched_profile_data.username : fetched_profile_data.graphql.user.username}.jpg`,
//     targeting_options: JSON.stringify(targetingOptionsDefault),
//     created_at: new Date().toISOString(),
//     ...(themeColor && { theme_color: themeColor as "Default" | "Purple" | "Indigo" | "Green" | "Amber" | "Pink" })
//   }

//   // Insert account in db
//   const { data, error } = await supabase
//     .from('accounts')
//     .insert(db_account as Tables<'accounts'>)
//     .select('id')
//     .single();

//   if (error) {
//     console.error(error);
//     console.error(error.code);

//     if (error.code == '23505') {
//       const error = new Error(`This ${accountPlatform === 'instagram' ? 'Instagram' : 'TikTok'} profile is already connected to an CreditCaptain account.`);
//       errors.add(error.message);
//       throw error;
//     } else 
//       errors.add(error.message);

//     throw error;
//   }

//   return data;
// };

/* ==================== */
/* Accounts */
/* ==================== */

export async function getUserHasAccounts(supabaseClient: AppSupabaseClient, userId: string): Promise<boolean> {
  const { data: userHasAccounts, error } = await supabaseClient
    .rpc('check_if_user_has_accounts', {
      user_id: userId,
    })
    .single();
  if (error) {
    console.error(error);
    throw error;
  }
  return Boolean(userHasAccounts);
}

export async function getUserHasUpgradableAccounts(supabaseClient: AppSupabaseClient, userId: string): Promise<boolean> {
  const { data: userHasAccounts, error } = await supabaseClient
    .rpc('check_if_user_has_upgradable_accounts', {
      user_id: userId,
    })
    .single();
  if (error) {
    console.error(error);
    throw error;
  }
  return Boolean(userHasAccounts);
}

// Stub implementation that returns an empty array since accounts table has been removed
export const getAllAccountsForUser = async (supabase: AppSupabaseClient) => {
  console.log('Warning: getAllAccountsForUser was called but accounts table has been removed');
  return [];
};

// Stub implementations for other account-related functions
export const getAccountById = async (supabase: AppSupabaseClient, accountId: string) => {
  console.log('Warning: getAccountById was called but accounts table has been removed');
  return null;
};

export const getAccountBySubscriptionId = async (supabase: AppSupabaseClient, subscription_id: string) => {
  console.log('Warning: getAccountBySubscriptionId was called but accounts table has been removed');
  return null;
};

export const getAccountByCustomerId = async (supabase: AppSupabaseClient, customer_id: string) => {
  console.log('Warning: getAccountByCustomerId was called but accounts table has been removed');
  return null;
};

export const getAccountByUsername = async (supabase: AppSupabaseClient, accountUsername: string) => {
  console.log('Warning: getAccountByUsername was called but accounts table has been removed');
  return null;
};


// export const getAllUpdatesForAccount = async (supabase: AppSupabaseClient, accountId: string, fetchAllUpdates: boolean = false) => {
//   let query = supabase
//     .from('updates')
//     .select('*')
//     .eq('account_id', accountId)
//     .order('created_at', { ascending: true })
//     .limit(1000);

//   console.log('fetchAllUpdates', fetchAllUpdates);
//   if (!fetchAllUpdates) {
//     const thirtyDaysAgo = new Date();
//     thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
//     query = query.gte('created_at', thirtyDaysAgo.toISOString());
//   }

//   const { data, error } = await query;

//   console.log('getAllUpdatesForAccount', data);

//   if (error) {
//     errors.add(error.message);
//     throw error;
//   }
//   return data;
// };


// export const getLast30DaysUpdatesForAccount = async (supabase: AppSupabaseClient, accountId: string) => {
//   const { data, error } = await supabase
//     .from('updates')
//     .select('*')
//     .eq('account_id', accountId)
//     .gte('created_at', new Date(new Date().setDate(new Date().getDate() - 30)))
//     .single();

//   if (error) {
//     errors.add(error.message);
//     throw error;
//   }
//   return data;
// };

export const updateAccount = async (supabase: AppSupabaseClient, accountId: string, updatedData: any) => {
  console.log('Warning: updateAccount was called but accounts table has been removed');
  return null;
};


/* ====================     */
/* Admin & Agent Users      */
/* ====================     */

export async function getIsAppAdmin(supabaseClient: AppSupabaseClient, authUser: User): Promise<boolean> {
  const { data: isUserAppAdmin, error } = await supabaseClient
    .rpc('check_if_user_is_app_admin', { user_id: authUser.id })
    .single();
  if (error) {
    throw error;
  }

  return Boolean(isUserAppAdmin);
}

export async function getIsAgentUser(supabaseClient: AppSupabaseClient, authUser: User): Promise<boolean> {
  const { data: isUserAgentUser, error } = await supabaseClient
    .rpc('check_if_user_is_agent', { user_id: authUser.id })
    .single();
  if (error) {
    throw error;
  }

  return Boolean(isUserAgentUser);
}

/* ==================== */
/* AUTH */
/* ==================== */

export const signInWithMagicLink = async (
  supabase: AppSupabaseClient,
  email: string
) => {
  const protocol = process.env.NODE_ENV === 'production' ? 'https://' : 'http://';
  const { error } = await supabase.auth.signInWithOtp({
    email,
    options: {
      emailRedirectTo: `${protocol}${ROOT_DOMAIN}/auth/callback`,
    },
  });

  if (error) {
    errors.add(error.message);
    throw error;
  }
};

export const signInWithPassword = async (
  supabase: AppSupabaseClient,
  email: string,
  password: string
) => {
  const { error } = await supabase.auth.signInWithPassword({
    email,
    password,
  });

  if (error) {
    errors.add(error.message);
    throw error;
  }
};

export const resetPassword = async (
  supabase: AppSupabaseClient,
  email: string
) => {
  const { error } = await supabase.auth.resetPasswordForEmail(email);

  if (error) {
    errors.add(error.message);
    throw error;
  }
};

export const updatePassword = async (
  supabase: AppSupabaseClient,
  password: string
) => {
  const { error } = await supabase.auth.updateUser({
    password,
  });

  if (error) {
    errors.add(error.message);
    throw error;
  }
};

export const signInWithProvider = async (
  supabase: AppSupabaseClient,
  provider: AuthProvider
) => {
  const { error } = await supabase.auth.signInWithOAuth({
    provider,
    options: {
      // redirectTo: `${PROTOCOL}${process.env.NODE_ENV === 'production' ? 'www.' : ''}${ROOT_DOMAIN}/auth/callback?provider=true`,
      redirectTo: `https://www.creditcaptain.com/auth/callback?provider=true`,
    },
  });

  if (error) {
    errors.add(error.message);
    throw error;
  }
};

export const signUp = async (
  supabase: AppSupabaseClient,
  email: string,
  password: string,
  name: string,
  phone: string,
) => {
    console.log(`🟡🟡🟡Signing up user with email: ${email}`)
    const protocol = process.env.NODE_ENV === 'production' ? 'https://' : 'http://';
    const { data:newUser, error: signUpError } = await supabase.auth.signUp({
      email,
      password,
      options: {
        emailRedirectTo: `${protocol}${ROOT_DOMAIN}/auth/callback`,
        data: {
          name,
          phone,
        },
      },
    });

    console.log("🟡  New user: ", newUser);

    // console.log("🟡  Sending notification: ", email);
    // console.log(newUser)
    // Notifications.sendNotification("signups", "New sign up", `New sign up from ${email}`, "🆕", true, email)

    if (signUpError) {
      errors.add(signUpError.message);
      console.error(signUpError);
      throw signUpError;
    }

  console.log("🟡  New user: ", newUser);

  // Split name into first and last name
  const [firstName, ...lastNameParts] = name.trim().split(' ');
  const lastName = lastNameParts.join(' ');

  // Create user_profile in supabase
  const { data: user_profile, error: insertError } = await supabase
    .from('user_profiles')
    .upsert({
      id: newUser.user.id,
      full_name: name,
      first_name: firstName,
      last_name: lastName || null,
      phone: phone,
      email: email,
    })
    .select()
    .single();

  console.log("🟡  new user profile: ", user_profile);

  if (insertError) {
    errors.add(insertError.message);
    throw insertError;
  }

  return newUser;
};

export const setupUserProfile = async (
  supabase: AppSupabaseClient,
  userId: string,
  profileData: {
    firstName?: string;
    lastName?: string;
    address?: {
      formattedAddress?: string;
      address1?: string;
      address2?: string;
      city?: string;
      region?: string;
      postalCode?: string;
      country?: string;
      lat?: number;
      lng?: number;
    };
    phone?: string;
    ssn?: string;
    dob?: Date;
    growthActive?: boolean; 
  }
) => {
  const updateData: any = {};
  if (profileData.firstName && profileData.lastName) {
    updateData.full_name = `${profileData.firstName} ${profileData.lastName}`;
  }
  if (profileData.firstName) updateData.first_name = profileData.firstName;
  if (profileData.lastName) updateData.last_name = profileData.lastName;
  if (profileData.address) updateData.address = profileData.address;
  if (profileData.phone) updateData.phone = profileData.phone;
  if (profileData.ssn) updateData.ssn = profileData.ssn;
  if (profileData.dob) updateData.dob = profileData.dob;
  if (profileData.growthActive !== undefined) updateData.growth_active = profileData.growthActive;
  

  const { data, error } = await supabase
    .from('user_profiles')
    .upsert({ id: userId, ...updateData })
    .select()
    .single()

  if (error) {
    console.error('Error in setupUserProfile:', error);
    errors.add(error.message);
    throw error;
  }

  return data;
};


/* ==================== */
/* Growth Settings      */
/* ==================== */

export const fetchGrowthSettings = async (supabase: AppSupabaseClient, userId: string) => {
  const { data, error } = await supabase
    .from('user_profiles')
    .select('growth_settings')
    .eq('id', userId)
    .single();
    
  if (error) throw error;
  return data.growth_settings;
};

export const updateGrowthSettings = async (supabase: AppSupabaseClient, userId: string, settings: Tables<'user_profiles'>['growth_settings']) => {
  const { data, error } = await supabase
    .from('user_profiles')
    .update({ growth_settings: settings })
    .eq('id', userId)
    .select()
    .single();
    
  if (error) throw error;
  return data.growth_settings;
};

/* ==================== */
/* Credit Reports       */
/* ==================== */

export const getCreditReportsForUser = async (supabase: ReturnType<typeof createClient<Database>>, userId: string, limit: number = 1) => {
  const { data, error } = await supabase
    .from('credit_reports')
    .select('*')
    .eq('user_id', userId)
    .order('created_at', { ascending: false })
    .limit(limit);

  if (error) {
    console.error(error);
    throw error;
  }

  return data || [];
};

// Keep the old function for backward compatibility
export const getLatestCreditReportForUser = async (supabase: ReturnType<typeof createClient<Database>>, userId: string) => {
  return getCreditReportsForUser(supabase, userId, 1);
};

/* ==================== */
/* Credit Health Insights */
/* ==================== */

export const getCreditHealthInsightsForUser = async (supabase: ReturnType<typeof createClient<Database>>, userId: string, limit: number = 5, includeDismissed: boolean = false) => {
  let query = supabase
    .from('credit_health_insights')
    .select('*')
    .eq('user_id', userId)
    .order('priority', { ascending: true });
    
  if (!includeDismissed) {
    query = query.eq('is_dismissed', false);
  }
  
  const { data, error } = await query.limit(limit);

  if (error) {
    console.error(error);
    throw error;
  }

  return data || [];
};

/* ==================== */
/* Disputes             */
/* ==================== */

export const getDisputesForUser = async (supabase: ReturnType<typeof createClient<Database>>, userId: string, status?: string, limit: number = 10) => {
  let query = supabase
    .from('disputes')
    .select('*')
    .eq('user_id', userId)
    .order('created_at', { ascending: false });
    
  if (status) {
    query = query.eq('status', status);
  }
  
  const { data, error } = await query.limit(limit);

  if (error) {
    console.error(error);
    throw error;
  }

  return data || [];
};

export const getDisputeActivities = async (supabase: ReturnType<typeof createClient<Database>>, disputeId: string, limit: number = 10) => {
  const { data, error } = await supabase
    .from('dispute_activities')
    .select('*')
    .eq('dispute_id', disputeId)
    .order('created_at', { ascending: false })
    .limit(limit);

  if (error) {
    console.error(error);
    throw error;
  }

  return data || [];
};

// Fetch latest 30 credit score updates
export const getLatestCreditScoreUpdates = async (supabase: AppSupabaseClient, userId: string) => {
  const { data, error } = await supabase
    .from('updates')
    .select('*')
    .eq('user_id', userId)
    .order('created_at', { ascending: false })
    .limit(30);

  if (error) {
    console.error(error);
    throw error;
  }

  return data;
};
