// https://supabase.com/docs/guides/auth/server-side/migrating-to-ssr-from-auth-helpers
import { createBrowserClient } from '@supabase/ssr';
import { Database } from '@/lib/database.types';
import { DEV_PORT, ROOT_DOMAIN } from '@/constants';
import { SupabaseClient } from '@supabase/supabase-js';

// Extract the base domain without port for cookie configuration
const baseDomain = ROOT_DOMAIN.replace(`:${DEV_PORT}`, '');

// Always use dot prefix for consistency between environments
const cookieDomain = process.env.NODE_ENV === 'development' ? '.af.com' : `.${baseDomain}`;

// Create a singleton instance of the Supabase client
let supabaseClient: SupabaseClient<Database> | null = null;

/**
 * Creates and returns a Supabase client for browser usage
 * Uses a singleton pattern to ensure only one client exists
 */
const getSupabaseBrowserClient = () => {
  if (typeof window === 'undefined') {
    // For SSR, create a fresh client without any auth state
    return createBrowserClient<Database>(
      process.env.NEXT_PUBLIC_SUPABASE_URL!,
      process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!,
      {
        cookieOptions: {
          domain: cookieDomain,
          path: '/',
          sameSite: 'lax',
          secure: process.env.NODE_ENV === 'development' ? false : true,
        }
      }
    );
  }

  // Return the existing client if already created
  if (supabaseClient) {
    return supabaseClient;
  }

  // Create a new client for browser
  supabaseClient = createBrowserClient<Database>(
    process.env.NEXT_PUBLIC_SUPABASE_URL!,
    process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!,
    {
      cookieOptions: {
        domain: cookieDomain, // Enable cross-subdomain cookie sharing
        path: '/',
        sameSite: 'lax',
        secure: process.env.NODE_ENV === 'development' ? false : true,
      }
    }
  );
  
  return supabaseClient;
};

// Export the browser client
export default getSupabaseBrowserClient();
