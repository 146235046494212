// LogSnag
import { LogSnag } from '@logsnag/next/server';
const logsnag = new LogSnag({
    project: process.env.NEXT_PUBLIC_LOGSNAG_PROJECT,
    token: '51ddfe54695d8914348eed3f78d045e2',
});

export class Notifications {
    public static async sendNotification(channel: string, title: string, icon = "💰", notify = false, userId?: string, data?: any) {
        console.log("🟡  Sending notification: ", title, " to channel: ", channel, " with data: ", data);
        try {
            await logsnag.track({
                channel: channel,
                event: title,
                ...(userId ? { user_id: userId } : {}),
                icon: icon,
                notify: notify,
                ...(data ? { tags: data } : {}),
            });
        } catch (error) {
            console.error('[LOGSNAG.TS] Error occurred while sending notification:', error);
        }
    }

    public static async sendTestNotification() {
        Notifications.sendNotification("testing-channel", "testing-event", "✨", true, '123', { hello: true, welcome: 1 })
    }
}